<template>
    <Layout>
      <PageHeader :title="title" :items="items"></PageHeader>
      <div class="row" id="notification">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row align-items-center mb-4">
                <div class="col-md-6">
                  <div
                    class="form-inline navbar-search"
                  >
                    <div class="input-group">
                      <input
                        name="title"
                        class="form-control bg-light border-0 small"
                        placeholder="Search Subject"
                        aria-label="Search"
                        aria-describedby="basic-addon2"
                        value=""
                        v-model="filter"
                        v-on:keyup="search"
                      />
                      <div class="input-group-append">
                        <button class="btn btn-search" @click="searchFor">
                          <i class="fas fa-search fa-sm"> </i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="table-responsive mb-0">
                <b-table
                  striped
                  bordered
                  :items="tableData.data"
                  :fields="fields"
                  :sort-by.sync="sortBy"
                  :per-page="0"
                  :current-page="currentPage"
                  :sort-desc.sync="sortDesc"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
                  responsive="sm"
                  class="table-bordered table-hover"
                  :key="key"
                >
                <template v-slot:cell(is_transfered)="row">
                  <b-button 
                    :variant="row.item.is_transfered == 1 ? 'success' : 'primary'" 
                    @click="updateStatus(row.item.id)" 
                    :disabled="row.item.is_transfered == 1"
                  >
                    {{ row.item.is_transfered == 1 ? 'Transferred' : 'Transfer' }}
                  </b-button>
                </template>
                  <template v-can="'edit-email-content'" v-slot:cell(edit)="row">
                    <router-link
                      :to="{
                        name: 'edit-email-content',
                        params: { id: row.item.id },
                      }"
                    >
                      <i class="fa fa-edit edit-i"></i>
                    </router-link>
                  </template>
                  <template v-slot:cell(email_type)="row">
                    <span
                      class="promotion_heading"
                      style="width: 220px"
                      v-if="row.item.email_type"
                      >{{ row.item.email_type }}</span
                    >
                    <span v-else>---</span>
                  </template>
                  <template v-slot:cell(from)="row">
                    <span
                      class="promotion_heading"
                      style="width: 220px"
                      v-if="row.item.from"
                      >{{ row.item.from }}</span
                    >
                    <span v-else>---</span>
                  </template>
                  <template v-slot:cell(replyTo)="row">
                    <span
                      class="promotion_heading"
                      style="width: 220px"
                      v-if="row.item.replyTo"
                      >{{ row.item.replyTo }}</span
                    >
                    <span v-else>---</span>
                  </template>
                  <template v-slot:cell(subject)="row">
                    <span
                      class="promotion_heading"
                      style="width: 220px"
                      v-if="row.item.subject"
                      >{{ row.item.subject }}</span
                    >
                    <span v-else>---</span>
                  </template>
                  <template v-slot:cell(delete)="row">
                    <div
                      type="submit"
                      @click.prevent="deleteEmailContent(row.item.id)"
                    >
                      <i class="mdi mdi-trash-can delete-i"></i>
                    </div>
                  </template>
                  <template v-slot:cell(content)="row">
                    <div class="text-center">
                      <img
                        src="/tick.svg"
                        height="20px"
                        style="color: red"
                        v-if="row.item.content != null && row.item.content != ''"
                      />
                      <img
                        src="/close.svg"
                        height="20px"
                        style="color: red"
                        v-else
                      />
                    </div>
                  </template>
                </b-table>
              </div>
              <div class="row">
                <div class="col">
                  <div
                    class="dataTables_paginate paging_simple_numbers d-flex justify-content-end"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="tableData.total"
                        :total-pages="tableData.total_pages"
                        :per-page="tableData.per_page"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  </template>
  
  <script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  import MixinRequest from "../../../mixins/request";
  import EmailContent from "../../../mixins/ModuleJs/survey-payout";
  
  export default {
    components: {
      PageHeader,
      Layout,
    },
    mixins: [MixinRequest, EmailContent],
  };
  </script>
  