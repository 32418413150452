import { survey_payout } from "../../js/path";

export default {
  data() {
    return {
      template_ref_no: null,
      table_header: [],
      fields: [
        {
            key: "id",
        },
        {
            key: "member_name",
            label: "Member Name",
            class: "c_touchpt_header text-center",
        },
        {
            key: "survey_title",
            label: "Survey Title",
            class: "c_touchpt_header text-center",
        },
        {
            key: "amount",
            label: "Amount",
            class: "c_touchpt_header text-center",
        },
        {
            key: "upi_id",
            label: "UPI ID",
            class: "c_touchpt_header text-center",
        },
        {
            key: "bank_details",
            label: "Bank Details",
            class: "c_touchpt_header text-center",
        },

        {
          key: "is_transfered",
          label: "Transfer Status",
          class: "c_touchpt_header text-center",
        },
      ],
      title: "Email Content",
      items: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "List",
        },
      ],
      tableData: [],
      sortBy: "id",
      sortDesc: true,
      filter: null,
      filterOn: [],
      key: 0,
      currentPage: 1,
      activeTab: "all",
      params: "",
      endpoint_dropdown: [],
      form: {
        endpoint_id: "",
        email_type: "",
        from: "",
        replyTo: "",
        subject: "",
        content: "",
        placeholders: "",
      },
    };
  },
  methods: {
    search(event) {
      if (this.filter.length > 1) {
        if (event.keyCode == 13) {
          this.fetchData(this.activeTab);
        }
      } else if (this.filter.length == 0) this.fetchData(this.activeTab);
    },
    searchFor() {
      if (this.filter.length > 0) {
        this.fetchData(this.activeTab);
      } else {
        this.fetchData(this.activeTab);
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async fetchData(pagination = null) {
      console.log("Fetching");
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = survey_payout.sureyPayoutUrl;
        if (pagination == "search") {
          url = survey_payout.sureyPayoutUrl + "?search=" + this.filter;
        } else if (pagination) {
          url = url + "?page=" + pagination;
          if (this.filter) {
            url = url + "&search=" + this.filter;
          }
        }
        const data = await this.getRequest(url);
        if (data.status) {
          const responseData = data.response;
          this.tableData = responseData;
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async updateStatus(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        // Find the index of the item in the table data
        let index = this.tableData.data.findIndex((e) => e.id === id);
        let isTransferredId = this.tableData.data[index].is_transfered_id;
        const url = survey_payout.surveyTranferStatus + "/" + isTransferredId;
    
        // Prepare the new status toggle
        const newStatus = !this.tableData.data[index].is_transfered;
        const memberId = this.tableData.data[index].member_id;
        const surveyId = this.tableData.data[index].survey_id;
    
        // Make the API request
        const data = await this.postRequest(url, { is_transfered: newStatus, member_id: memberId, survey_id: surveyId });
    
        if (data.status) {
          // Update the table data with the response
          // const responseData = data.response;
          this.tableData.data[index] = this.fetchData('all');
    
          // Show a success toast
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
    
        // Refresh the component key to force re-render
        this.key += 1;
      } catch (err) {
        // Show an error toast
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    }    
  },
  watch: {
    currentPage: {
      handler: function (value) {
        if (value) {
          this.params = `&page=${value}`;
          this.fetchData(value);
          this.pageNo = this.currentPage;
        }
      },
    },
  },
  created() {
      this.fetchData("all");
  },
};
